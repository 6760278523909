<template>
  <div>
    <section id="account-section" class="section section-secondary jpadding jpadding-20">
      <div class="jcard jcard-main jcard-nohover">
        <div class="card-title">
          <h3>Talkii Account</h3>
          <!-- <button @click="showChangeEmailDialog" class="jbtn-text ml-3">
            <span class="fa fa-pencil"></span>
          </button> -->
        </div>
        <div id="talkii-id" class="">
          <div class="user-icon">{{ userLabel }}</div>
          <!-- <p>
            <label
              ><strong><i class="fa fa-envelope"></i> Email</strong></label
            >
            {{ user.email }}
          </p> -->
          <!-- <div class="d-flex">
          <p><small>TYPE</small><br><label class="badge badge-pill badge-secondary">APP</label></p>
          <p class="ml-3"><small>STATUS</small><br><label class="badge badge-pill badge-success">ACTIVE</label></p>
          </div> -->
          <p>
            ID <strong>{{ userId.substring(0, 12).toUpperCase() }}</strong>
          </p>
          <p>
            <strong>{{ user.email }}</strong>
          </p>
          <div class="d-flex">
            <p>
              <small>TYPE</small><br /><label class="badge badge-pill badge-secondary">{{
                accountTypeToStr(user.accType).toUpperCase() }}</label>
            </p>
            <p class="ml-3">
              <small>STATUS</small><br />
              <label class="badge badge-pill"
                v-bind:class="{ 'badge-success': hasActiveStatus(user.status), 'badge-danger': !hasActiveStatus(user.status) }">{{
                  accountStatusToStr(user.status).toUpperCase() }}</label>
            </p>
          </div>
          <button class="jbtn jbtn-outline jbtn-xs my-2" @click="showChangeEmailDialog">
            Change email
          </button>
          <!-- <p><small>EMAIL</small><br><strong>{{ user.email }}</strong></p>
          <p><small>TALKII ID</small><br><strong>{{ userId.substring(0, 12) }}</strong></p> -->
        </div>
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>

        <div class="card-title mt-3">
          <h3>Personal information</h3>
          <button @click="showDialog" class="jbtn-text ml-3">
            <span class="fa fa-pencil"></span>
          </button>
        </div>

        <!-- INFO -->
        <!-- <div class="row">
          <div class="col">
            <label
              ><strong><i class="fa fa-envelope"></i> Email</strong></label
            >
            <p>{{ user.email }}</p>
          </div>
        </div> -->

        <div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fa fa-user"></i> Name</strong></label>
              <p>{{ user.name }}</p>
            </div>
            <div class="col">
              <label><strong><i class="fa fa-phone-alt"></i> Phone</strong></label>
              <p>{{ user.phone }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fa fa-map-marker"></i> Address</strong></label>
              <p>{{ user.addr }}</p>
            </div>
            <div class="col">
              <label><strong><i class="fa fa-map-marker"></i> City</strong></label>
              <p>/</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label><strong><i class="fa fa-map-marker"></i> Zip</strong></label>
              <p>{{ user.zip }}</p>
            </div>
            <div class="col">
              <label><strong><i class="fa fa-globe-europe"></i> Country</strong></label>
              <p>{{ user.country }}</p>
            </div>
          </div>
        </div>

        <!-- ----- edit user profile modal --------------------------------- -->
        <b-modal hide-header="true" hide-footer="true" id="edit-user-modal" ref="edit-user-modal" centered
          title="BootstrapVue">
          <div class="jdialog-top">
            <div class="close-container">
              <h3><strong>Edit profile</strong></h3>
              <span @click="$bvModal.hide('edit-user-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
            </div>
            <p><small>Keep your information up to date.</small></p>
          </div>
          <div class="jdialog-main">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="name"> <i class="fal fa-user"></i> Name </label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validName(editData.name) && bluredName,
                  }" v-on:blur="bluredName = true" v-model="editData.name" />
                  <div class="invalid-feedback">Please tell us your name.</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Address</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.addr) && bluredAddr,
                  }" v-on:blur="bluredAddr = true" v-model="editData.addr" />
                  <div class="invalid-feedback">Please enter an address.</div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-city"></i> City</label>
                  <input type="text" class="form-control" v-model="editData.city" />
                  <div class="invalid-feedback">Please enter a city.</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-map-marker-alt"></i> Zip</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(editData.zip) && bluredZip,
                  }" v-on:blur="bluredZip = true" v-model="editData.zip" />
                  <div class="invalid-feedback">Please enter a zip code.</div>
                </div>
              </div>

              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-phone-alt"></i> Phone</label>
                  <input type="text" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validPhone(editData.phone) && bluredPhone,
                  }" v-on:blur="bluredPhone = true" v-model="editData.phone" />
                  <div class="invalid-feedback">
                    Please enter a phone number.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="email"><i class="fal fa-globe-europe"></i> Country</label>
              <b-form-select v-model="editData.country" :options="countriesList"></b-form-select>
            </div>
            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>
          <div class="jdialog-bottom with-cancel">
            <button @click="$bvModal.hide('edit-user-modal')" class="jbtn jbtn-sm jbtn-red">
              <i class="fa fa-times"></i> Cancel
            </button>
            <button @click="updateUserInfo" class="jbtn jbtn-sm">
              <i class="fa fa-save"></i> Update
            </button>
          </div>
        </b-modal>

        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>

        <!-- ----- change email modal --------------------------------- -->
        <b-modal hide-header="true" hide-footer="true" id="change-email-modal" ref="change-email-modal" centered
          title="BootstrapVue">
          <div class="jdialog-top">
            <div class="close-container">
              <h3><strong>Change email</strong></h3>
              <span @click="$bvModal.hide('change-email-modal')" class="ml-auto jclose"><i
                  class="fa fa-times "></i></span>
            </div>
            <p>
              <small>In order to change your email, please re-enter your
                password.<br />Note that you will need to use your new email to
                login.</small>
            </p>
          </div>
          <div class="jdialog-main">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="name">
                    <i class="fal fa-lock"></i> Current password
                  </label>
                  <input type="password" v-bind:class="{
                    'form-control': true,
                    'is-invalid':
                      !validPassword(currentPassword) && bluredPassword,
                  }" v-on:blur="bluredPassword = true" v-model="currentPassword" />
                  <div class="invalid-feedback">
                    Please enter your current password.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md">
                <div class="form-group">
                  <label for="email"><i class="fal fa-envelope"></i> New email</label>
                  <input type="email" v-bind:class="{
                    'form-control': true,
                    'is-invalid':
                      !validEmail(editEmailData.email) && bluredEmail,
                  }" v-on:blur="bluredEmail = true" v-model="editEmailData.email" />
                  <div class="invalid-feedback">
                    Please enter a valid email.
                  </div>
                </div>
              </div>
            </div>

            <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
              {{ validationMsg }}
            </b-alert>
          </div>
          <div class="jdialog-bottom with-cancel">
            <button @click="$bvModal.hide('change-email-modal')" class="jbtn jbtn-sm jbtn-red">
              <i class="fa fa-times"></i> Cancel
            </button>
            <button @click="initUpdateEmail" class="jbtn jbtn-sm">
              <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else key="button"><i class="fa fa-save"></i> Change</span>
            </button>
          </div>
        </b-modal>

        <!-- Email Verification -->
        <h3 class="mb-4 mt-3">Email verification</h3>
        <div class="jrow-settings">
          <div>
            <label><strong><i class="fa fa-envelope"></i> Your email address is</strong></label>
            <span v-if="!emailVerified" class="badge badge-danger ml-2">unverified</span>
            <span v-else class="badge badge-success ml-2">verified</span>
          </div>
          <button class="jbtn jbtn-outline jbtn-xs" @click="sendEmailVerification">
            Verify
          </button>
        </div>

        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>

        <!-- Account deletion -->
        <h3 class="mb-4 mt-3">Delete Account</h3>
        <div class="jrow-settings">
          <div>
            <label><strong> Send a request to delete all your data.</strong></label>
            <div>
            </div>
          </div>
          <span v-if="user.deletionRequest" class="badge badge-danger ml-2">REQUESTED!</span>
          <p v-if="user.deletionRequest">
            <small>
              If you changed your mind try to contact us ASAP via email at contact@sovi.lu
            </small>
          </p>
          <button v-if="!user.deletionRequest" class="jbtn jbtn-red jbtn-xs"
            @click="$refs['delete-account-modal'].show();">
            Delete Accoount
          </button>
        </div>

        <!-- ----- delete account modal --------------------------------- -->
        <b-modal hide-header="true" hide-footer="true" id="delete-account-modal" ref="delete-account-modal" centered
          title="BootstrapVue">
          <div class="jdialog-top">
            <div class="close-container">
              <h3><strong>Delete account</strong></h3>
              <span @click="$bvModal.hide('delete-account-modal')" class="ml-auto jclose"><i
                  class="fa fa-times "></i></span>
            </div>
            <p>
              Are you sure you want to irreversibly delete your account, including all related data?
            </p>
          </div>
          <div class="jdialog-main">
            <p>
              <small>If you confirm, a deletion request will be created. The request will be treated within a week. Make
                sure you canceled all your Talkii subscriptions.</small>
            </p>
          </div>
          <div class="jdialog-bottom with-cancel">
            <button @click="$bvModal.hide('delete-account-modal')" class="jbtn jbtn-sm jbtn-grey">
              <i class="fa fa-times"></i> Cancel
            </button>
            <button @click="sendAccountDeletionRequest" class="jbtn jbtn-sm jbtn-red">
              <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else key="button"><i class="fa fa-trash"></i> DELETE</span>
            </button>
          </div>
        </b-modal>


      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
import C from "@/constants";
import P from "@/plans";
import toast from "@/assets/js/toast";

export default {
  name: "Account",
  data() {
    return {
      isLoading: false,
      userId: "",

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //edit email form
      validChangeEmail: false,
      currentPassword: "",
      bluredPassword: false,
      bluredEmail: false,
      editEmailData: {
        email: "",
      },
      //edit user info form
      valid: false,
      bluredName: false,
      bluredAddr: false,
      bluredZip: false,
      bluredPhone: false,
      // validName: false,
      // validAddr: true,
      // validZip: true,

      editData: {
        name: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
        phone: "",
      },
      countriesList: C.COUNTRIES,

      // delivery addr options
      deliveryAddrChecked: true,
      // email verification
      emailVerified: false,
    };
  },
  methods: {
    // setUserDoc(data){
    //       this.$store.commit("setUser", doc.data());

    // }
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    showChangeEmailDialog() {
      this.$refs["change-email-modal"].show();
    },
    initUpdateEmail() {
      this.isSaving = true;
      if (!this.validateChangeEmail()) {
        this.isSaving = false;
        return;
      }

      //re-reauthenticate user first, than try updating email
      this.reauthenticate();
    },
    updateEmail() {
      var user = firebase.auth().currentUser;

      //update firebase auth
      user
        .updateEmail(this.editEmailData.email)
        .then(() => {
          // Update successful.
          //update firebase user doc
          var docRef = db.collection("talkii_root").doc(user.uid);
          docRef
            .set(this.editEmailData, { merge: true })
            .then(() => {
              //reset data
              this.currentPassword = "";
              this.editEmailData.email = "";
              this.bluredEmail = false;
              this.bluredPassword = false;
              this.isSaving = false;
              //hide modal
              this.$refs["change-email-modal"].hide();
              //show toast
              toast.success("Your email has been udated.");
            })
            .catch((error) => {
              toast.error("Could't update email. " + error.message);
              this.isSaving = false;
            });
        })
        .catch((error) => {
          toast.error("Could't update email. " + error.message);
          this.isSaving = false;
        });
    },
    showDialog() {
      this.editData.name = this.user.name;
      this.editData.addr = this.user.addr;
      this.editData.zip = this.user.zip;
      this.editData.country = this.user.country;
      this.editData.phone = this.user.phone;
      this.$refs["edit-user-modal"].show();
    },
    updateUserInfo() {
      if (!this.validate()) return;
      var docRef = db
        .collection("talkii_root")
        .doc(firebase.auth().currentUser.uid);
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.$refs["edit-user-modal"].hide();
          toast.success("User profile updated.");
        })
        .catch((error) => {
          toast.error("Could't update user profile. " + error.message);
        });
    },

    updateIcon() { },
    addDeliveryAddress() {
      console.log(this.deliveryAddrChecked);
    },
    sendEmailVerification() {
      var user = firebase.auth().currentUser;

      user
        .sendEmailVerification()
        .then(function () {
          // Email sent.
          toast.success(
            "We have sent you a verification email. Please check your inbox."
          );
        })
        .catch(function (error) {
          toast.error("Sending verification email failed. " + error.message);
        });
    },
    async sendAccountDeletionRequest() {
      if (!this.userId || !this.user || !this.user.email) {
        toast.error("Missing data. Please reauthenticate");
        return null;
      }

      this.isSaving = true;
      // console.log("CREATING A NEW DELETION REQUEST FOR USER " + this.userId);
      try {
        const delRequest = {
          userId: this.userId,
          email: this.user.email
        };
        // console.log(delRequest);
        // create deletion request document
        const docRef = await db.collection("deletion_requests").add(delRequest);
        console.log("Created request - updating userDoc");
        // update user document
        await db.collection("talkii_root").doc(this.userId).update({
          deletionRequest: docRef.id
        });

        this.user.deletionRequest = docRef.id;
        this.isSaving = false;
        this.$refs["delete-account-modal"].hide();
        toast.success("Deletion request sent!")
      } catch (error) {
        toast.error(error.message);
        this.isSaving = false
        this.$refs["delete-account-modal"].hide();
      }
    },
    reauthenticate() {
      var user = firebase.auth().currentUser;
      var credentials = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.currentPassword
      );

      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          //change email after re-authenticate
          this.updateEmail();
        })
        .catch((error) => {
          this.isSaving = false;
          toast.error(error.message);
        });
    },
    validateChangeEmail() {
      this.bluredPassword = true;
      if (!this.validPassword(this.currentPassword)) {
        this.validChangeEmail = false;
        return false;
      }

      this.bluredEmail = true;
      this.editEmailData.email = this.editEmailData.email.trim().toLowerCase();
      if (!this.validEmail(this.editEmailData.email)) {
        this.validChangeEmail = false;
        return false;
      }

      this.validChangeEmail = true;
      return true;
    },
    validate() {
      //Check first name
      this.bluredName = true;
      if (!this.validName(this.editData.name)) {
        this.valid = false;
        return false;
      }

      this.bluredAddr = true;
      if (!this.validInput(this.editData.addr)) {
        this.valid = false;
        return false;
      }
      // this.bluredCity = true;
      // if (!this.validInput(this.editData.city)) {
      //   this.valid = false;
      //   return false;
      // }
      this.bluredZip = true;
      if (!this.validInput(this.editData.zip)) {
        this.valid = false;
        return false;
      }
      this.bluredPhone = true;
      if (!this.validPhone(this.editData.phone)) {
        this.valid = false;
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validName(name) {
      return name.trim().length > 2;
    },
    // validAddr(addr) {
    //   return addr.trim().length > 0;
    // },
    // validZip(zip) {
    //   return zip.trim().length > 0;
    // },
    validPhone(phone) {
      return phone.trim().length > 5;
    },
    validPassword(password) {
      return password.trim().length > 7;
    },
    accountTypeToStr(type) {
      return P.TYPE_INDEX[type];
    },
    accountStatusToStr(status) {
      return P.STATUS_INDEX[status];
    },
    hasActiveStatus(status) {
      if (status == 0 || status == 1 || status == 2 || status == 10 || status == 12) return true;
      else return false;
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      },
    },
  },
  beforeCreate() {
    //init
    // firebase.auth().currentUser.reload();
    this.emailVerified = firebase.auth().currentUser.emailVerified;

    var docRef = db
      .collection("talkii_root")
      .doc(firebase.auth().currentUser.uid);
    // let self = this;
    docRef.onSnapshot((doc) => {
      var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
      console.log("Remote userDoc update. source: ", source);
      this.$store.commit("setUser", doc.data());
    });
    // .get()
    // .then(function(doc) {
    //   if (doc.exists) {
    //     console.log("firebase - loaded document data: ", doc.data());
    //     self.$store.commit("setUser", doc.data());
    //   } else {
    //     // doc.data() will be undefined in this case
    //     console.log("No such document!");
    //     //show error screen
    //   }
    // })
    // .catch(function(error) {
    //   console.log("Error getting document:", error);
    //   //show error screen
    // });
  },
  created() {
    this.userId = firebase.auth().currentUser.uid;
  },
};
</script>

<style scoped></style>
